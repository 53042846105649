import React, { Suspense, useEffect, useState} from 'react'
import './App.css';
import Sidebar from './components/Sidebar';
import Navbar from './components/Narbar';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AccountServices } from './pages/account/AccountServices';
import './interceptors/interceptors';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

import { FooterContainer } from './components/FooterSection';
import KeycloakServices from './services/KeycloakService';
import FullPageLoader from './components/FullPageLoader';

const Home = React.lazy(() => import("./pages/home/Home"));
const About = React.lazy(() => import("./pages/about/About"));
const Events = React.lazy(() => import("./pages/event/Events"));
const DetailChallenge = React.lazy(() => import("./pages/event/detailevent/DetailChallenge"));
const Riders = React.lazy(() => import("./pages/standings/Riders"));
const Organizations = React.lazy(() => import("./pages/standings/Organizations"));
const Teams = React.lazy(() => import("./pages/standings/Teams"));
const Challenge = React.lazy(() => import("./pages/challenge/Challenge"));
const TripLog = React.lazy(() => import("./pages/triplog/TripLog"));
const TeamInformation = React.lazy(() => import("./pages/account/teaminformation/TeamInformation"));
const MyProfile = React.lazy(() => import("./pages/account/MyProfile"));
const ScrollToTop = React.lazy(() => import("./components/ScrollToTop"));
const SignIn = React.lazy(() => import("./pages/login/SignIn"));
const ProvideAuth = React.lazy(() => import("./security/ProvideAuth"));
const AuthRoute = React.lazy(() => import("./security/AuthRoute"));
const LeaderBoards = React.lazy(() => import("./pages/standings/LeaderBoards"));
const Dashboard = React.lazy(() => import("./pages/account/dashboard/Dashboard"));
const FrequentAQ = React.lazy(() => import("./pages/about/faq/FrequentAQ"));
const DetailEvent = React.lazy(() => import("./pages/event/detailevent/DetailEvent"));
const Settings = React.lazy(() => import("./pages/Tournament/Settings"));
const Standings = React.lazy(() => import("./pages/Tournament/Standings"));
const TournamentList = React.lazy(() => import("./pages/Tournament/TournamentList"));
const ScheduleDetail = React.lazy(() => import("./pages/Tournament/ScheduleDetail"));

const client = new ApolloClient({
  uri: "https://content-sea.fromlabs.com/api/content/qbp/graphql",
  cache: new InMemoryCache()
});
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const accountServices = new AccountServices();

  const toggle = () =>  {
      setIsOpen(!isOpen);
  }

  const saveKeycloakUser = () => {
    accountServices.checkKeycloakUser(KeycloakServices.getEmployeeId(), KeycloakServices.getUsername(), KeycloakServices.getEmail())
    .then(data => {
      if (data && data.status && data.status.code === 1)
      {
        KeycloakServices.updateToken();
      } else if (data && data.status && data.status.code === -2) {
        if (localStorage.getItem("isRequired") === null) {
          localStorage.setItem("isRequired", 1);
          window.location ="/profile";
        }
        
      }
    })
  }

  useEffect(() => {
    if (!(localStorage.getItem("isRequired") === '2' && window.location.pathname === '/profile')) {

      if(KeycloakServices.getParseToken()){
        saveKeycloakUser();
      }
    }
  },[])

  return (
    <Suspense fallback={<FullPageLoader />}>
      <ProvideAuth>
        <ApolloProvider client={client}>
          <Router>
              <Sidebar isOpen={isOpen} toggle={toggle}/>
              <Navbar toggle={toggle}/>
              <ScrollToTop />
            <Switch>
              <Route path='/' exact component={(props) => <Home {...props} />} />
              <Route path='/home' exact component={(props) => <Home {...props} />} />
              <AuthRoute path='/resources' component={(props) => <About {...props} />} />
              <AuthRoute path='/faq' component={(props) => <FrequentAQ {...props} />} />
              <AuthRoute path='/events' component={(props) => <Events {...props} />} />
              <AuthRoute path='/event/:id' component={(props) => <DetailEvent {...props} />}/>
              <AuthRoute path='/challenge/:id/stats'  component={(props) => <DetailChallenge {...props} />} />
              <AuthRoute path='/allteams' component={(props) => <TeamInformation {...props} />}/>
              <AuthRoute path='/riders' component={(props) => <Riders {...props} />}/>
              <AuthRoute path='/teams' component={(props) => <Teams {...props} />}/>
              <AuthRoute path='/organizations' component={(props) => <Organizations {...props} />} />
              <AuthRoute path='/leaderboards' component={(props) => <LeaderBoards {...props} />}/>
              <AuthRoute path='/challenge' component={(props) => <Challenge {...props} />}/>
              <AuthRoute path='/personal' component={(props) => <TripLog {...props} />}/>
              <AuthRoute path='/profile' component={(props) => <MyProfile {...props} />}/>
              {/* <AuthRoute path='/team_information' component={TeamInformation} /> */}
              <AuthRoute path='/dashboards' component={(props) => <Dashboard {...props} />}/> 
              <AuthRoute path='/settings' component={(props) => <Settings {...props} />}/>
              <AuthRoute path='/tournament' component={(props) => <TournamentList {...props} />}/> 
              <AuthRoute path='/standings' component={(props) => <Standings {...props} />}/>
              <AuthRoute path='/schedule-detail' component={(props) => <ScheduleDetail {...props} />}/>  
              {/* <Route path='/register' component={SignUp} /> */}
              <Route path='/login' component={(props) => <SignIn {...props} />}/>
              <Route path='*' component={(props) => <Home {...props} />}/>
            </Switch>
            <FooterContainer />
          </Router>
        </ApolloProvider>
      </ProvideAuth>
    </Suspense>
  );
}

export default App;

import axios from "axios";
import { API_BASE_URL, EVENT_ID } from "../../constants/constants";

export class AccountServices  {

    //  ----------------- API for profile information------------------

    changePassword (password) {
        return axios.post(`${API_BASE_URL}/api/profile/change_password`, password, null)
        .then(res => res.data).catch(error => console.log(error));
    }

    getProfileStatistic () {
        return axios.get(`${API_BASE_URL}/api/profile/v2`, {
            // params: {eventId: EVENT_ID}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getPersonalStatistic () {
        return axios.get(`${API_BASE_URL}/api/profile/v2/stats`, {
            // params: {eventId: EVENT_ID}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getRecentStats (start, length, sortColumn, sortDirection) {
        return axios.get(`${API_BASE_URL}/api/profile/v2/recentstats`, {
            params: {start, length, sortColumn, sortDirection}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getRecentAudits (search, start, length, sortColumn, sortDirection) {
        return axios.get(`${API_BASE_URL}/api/profile/v2/recentaudits`, {
            params: {search, start, length, sortColumn, sortDirection}
        }).then(res => res.data).catch(error => console.log(error));
    }
    
    getRidersChallenge (eventId, draw, start, length, search) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/riders`, null, {
            params: {eventId, draw, start, length, search}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getRidersChallenged (eventId, start, length, search, sortColumn, sortDirection) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/riders/challenged`, null, {
            params: {eventId, start, length, search, sortColumn, sortDirection}
        }).then(res => res.data).catch(error => console.log(error));
    }

    acceptRiderChallenge (eventId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/rider/v2/accept`, null, {
            params: {eventId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    declineRiderChallenge (eventId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/rider/v2/decline`, null, {
            params: {eventId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    revokeRiderChallenge (eventId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/rider/revoke`, null, {
            params: {eventId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    resendRiderChallenge (eventId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/rider/resend`, null, {
            params: {eventId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getTeamsChallenge (eventId, teamId, draw, start, length, search) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/teams`, null, {
            params: {eventId, teamId, draw, start, length, search}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getTeamsChallengeV2 (eventId, teamId, start, length, search, sortColumn, sortDirection) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/teams/v2`, null, {
            params: {eventId, teamId, start, length, search, sortColumn, sortDirection}
        }).then(res => res.data).catch(error => console.log(error));
    }

    acceptTeamChallenge (eventId, requesterId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/team/v2/accept`, null, {
            params: {eventId, requesterId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    declineTeamChallenge (eventId, requesterId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/team/v2/decline`, null, {
            params: {eventId, requesterId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    revokeTeamChallenge (eventId, requesterId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/team/revoke`, null, {
            params: {eventId, requesterId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    resendTeamChallenge (eventId, responderId) {
        return axios.post(`${API_BASE_URL}/api/profile/challenge/team/resend`, null, {
            params: {eventId, responderId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    updateProfile(userInfo){
        return axios.put(`${API_BASE_URL}/api/profile/update`, userInfo)
        .then(res => res.data).catch(error => console.log(error));
    }

    //  ----------------- API for team information------------------

    getTeamsManage ()  {
        return axios.get(`${API_BASE_URL}/api/manage/teams`, null)
        .then(res => res.data).catch(error => console.log(error));
    }

    getTeamCaptains (id) {
        return axios.get(`${API_BASE_URL}/api/manage/teams/${id}`, null)
        .then(res => res.data).catch(error => console.log(error));
    }

    updateTeamInfo (id, payload) {
        return axios.post(`${API_BASE_URL}/api/manage/teams/${id}/update`, payload, 
        {
            headers: {
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(res => res.data).catch(error => console.log(error))
    }

    createTeam (teamInfo) {
        return axios.post(`${API_BASE_URL}/api/manage/team/create`, teamInfo
        ).then(res => res.data).catch(error => console.log(error));
    }

    createDepartment (compName, compAddr, employees) {
        return axios.post(`${API_BASE_URL}/api/manage/department/create`, null, {
            params: {compName, compAddr, employees}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getMembersTeamManage (id, draw, start, length, search, sortColumn, sortDirection) {
        return axios.get(`${API_BASE_URL}/api/manage/teams/${id}/members`, {
            params: {draw, start, length, search, sortColumn, sortDirection}
        }).then(res => res.data).catch(error => console.log(error));
    }

    changeMemberRole (id, userId, role) {
        return axios.post(`${API_BASE_URL}/api/manage/teams/${id}/member/role`, null, {
            params: {userId, role}
        }).then(res => res.data).catch(error => console.log(error));
    }

    removeMemberById (id, userId) {
        return axios.post(`${API_BASE_URL}/api/manage/teams/${id}/member/remove`, null, {
            params: {userId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getMembersAvailable (id, draw, start, length, search, sortColumnNumber, sortDirection) {
        return axios.get(`${API_BASE_URL}/api/manage/teams/${id}/members/available`, {
            params: {draw, start, length, search, sortColumnNumber, sortDirection}
        }).then(res => res.data).catch(error => console.log(error));
    }

    addMemberByCode (id, regCode) {
        return axios.post(`${API_BASE_URL}/api/manage/teams/${id}/member/addbyCode`, null, {
            params: {regCode}
        }).then(res => res.data).catch(error => console.log(error));
    }

    addMemberById (id, userId) {
        return axios.post(`${API_BASE_URL}/api/manage/teams/${id}/member/addbyId`, null, {
            params: {userId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    uploadAvatar (file) {
        return axios.post(`${API_BASE_URL}/api/profile/avatar/upload`, file, 
        {
            headers: {
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(res => res.data).catch(error => console.log(error))
    } 

    //  ----------------- API for keycloak------------------
    checkKeycloakUser (employeeId, username, email){
        return axios.get(`${API_BASE_URL}/api/users/check-keycloak-user`, {
            params: {employeeId, username, email}
        })
        .then(res => res.data)
    }
}
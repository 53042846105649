import axios from 'axios';
import { createBrowserHistory } from 'history';
import { whitelist } from '../security/config';
import { API_BASE_URL, CLIENT_ID } from '../constants/constants';
import KeycloakServices from '../services/KeycloakService';

const history = createBrowserHistory();
let isRefreshingToken = false;
let subscribers = [];

axios.interceptors.request.use((config) => {
        // add access token to requests
        const basePath = getBasePath(config.url);
        if (!whitelist.includes(basePath) && localStorage.getItem('token') !== null) {
            config.headers.token = `${localStorage.getItem('token')}`;
            config.headers.clientId = `${CLIENT_ID}`;
        } else {
            config.headers.clientId = `${CLIENT_ID}`;
        }
        
        if (config.headers.token != null && config.url.indexOf("api/users/save-keycloak-user") === -1 && config.url.indexOf("api/profile/update") === -1 
            && config.url.indexOf("api/profile/v2") === -1) {
            if (localStorage.getItem("isRequired") === '2') {
                window.location = '/profile'
            }
        }

        return config;
    }, function (error) {
        console.log(error)
        return Promise.reject(error);
    }
);

axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        
        console.log(error.response)
        if (!error.response) {
            localStorage.clear();
            KeycloakServices.doLogout();
                   history.push('/')
        }
        let res = error.response;
        if (res) {
            let responseURL = res.request.responseURL;
            switch(res.status) {
                case 500:
                    // if(responseURL.indexOf('api/programs'))
                    //     showNotification('error', 'Error Message', res.data.errorMessage);
                    // else history.push('/500');
                    break;
                case 400:
                    return res;
                // case 404:
                //     history.push('/404');
                //     break;
                case 401:
                    // try to refresh token then try again one more time
                    // const originalRequest = error.config;
                    // if (!isRefreshingToken) {
                    //     isRefreshingToken = true;
                    //     return refreshToken().then((res) => {
                    //         error.config.headers.Authentication = `Bearer ${res.access_token}` // set new token
                    //         isRefreshingToken = false;
                    //         onAccessTokenFetched(res.access_token);
                    //         return axios.request(error.config); // retry request
                    //     }).catch(err => {
                    //         if (err.response.status === 401) {
                    //             logOut();
                    //             history.push('/login');
                    //             isRefreshingToken = false;
                    //         }
                    //     });
                    // } else {
                    //     subscribers.push(accessToken => {
                    //         originalRequest.headers.Authorization = 'Bearer ' + accessToken;
                    //         axios.request(originalRequest);
                    //     });
                    // }
                    // break;
                    if (responseURL.indexOf('www.strava.com/oauth/authorize') !== -1) {
                        localStorage.removeItem('accessTokenStrava');
                        localStorage.removeItem('stravaBefore');
                        localStorage.removeItem('athlete');
                        return res;
                    } else if (responseURL.indexOf('www.fitbit.com/oauth2/authorize') !== -1) {
                        localStorage.removeItem('accessTokenFitbit');
                        localStorage.removeItem('fitbitDate');
                        localStorage.removeItem('user_id_fit');
                        return res;
                    } else {
                        localStorage.clear();
                        KeycloakServices.doLogout();
                        window.location = "/";
                        break;
                    }
                    
                default:
            }
        }
        return Promise.reject(error);
    }
);

function onAccessTokenFetched(accessToken) {
    subscribers.forEach(callback => callback(accessToken));
    subscribers = [];
}

function getBasePath(url) {
    let urlPath = url.replace(API_BASE_URL, '');
    if (urlPath.startsWith('/')) {
        urlPath = urlPath.slice(1);
    }
    const parts = urlPath.split('/');
    return parts.length > 0 ? parts[0] : '';
}
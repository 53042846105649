export const navItems = [
    {
        id: 1,
        title: "Home",
        path: "./",
        cName: "nav-item",
    },
    {
        id: 2,
        title: "Services",
        path: "./services",
        cName: "nav-item",
    },
    {
        id: 3,
        title: "Products",
        path: "./products",
        cName: "nav-item",
    },
    {
        id: 4,
        title: "Contact Us",
        path: "./contactus",
        cName: "nav-item",
    },
];

export const aboutDropdown = [
    {
        id: 1,
        title: "RESOURCES",
        path: "/resources",
        cName: "dropdown-link",
    },
    {
        id: 2,
        title: "FAQ",
        path: "/faq",
        cName: "dropdown-link",
    },
];

export const standingsDropdown = [
    {
        id: 1,
        title: "RIDERS",
        path: "/riders",
        cName: "dropdown-link",
    },
    {
        id: 2,
        title: "TEAMS",
        path: "/teams",
        cName: "dropdown-link",
    },
    {
        id: 3,
        title: "ORGANIZATIONS",
        path: "/organizations",
        cName: "dropdown-link",
    },
    {
        id: 4,
        title: "OVERVIEWS",
        path: "/leaderboards",
        cName: "dropdown-link",
    },
];

export const challengeDropdown = [
    {
        id: 1,
        title: "EVENT",
        path: "/events",
        cName: "dropdown-link",
    },
    {
        id: 2,
        title: "CHALLENGE",
        path: "/challenge",
        cName: "dropdown-link",
    },
];

export const profileDropdown = [
    {
        id: 1,
        title: "MY PROFILE",
        path: "/profile",
        cName: "dropdown-link",
    },
    {
        id: 2,
        title: "TEAMS",
        path: "/allteams",
        cName: "dropdown-link",
    },
];

export const profileDropdownAdmin = [
    {
        id: 1,
        title: "MY PROFILE",
        path: "/profile",
        cName: "dropdown-link",
    },
    {
        id: 2,
        title: "TEAMS",
        path: "/allteams",
        cName: "dropdown-link",
    },
    {
        id: 3,
        title: "DASHBOARDS",
        path: "/dashboards",
        cName: "dropdown-link",
    },
];

export const tournamentDropdown = [
    {
        id: 1,
        title: "SETTINGS",
        path: "/settings",
        cName: "dropdown-link"
    },
    {
        id: 2,
        title: "STANDINGS",
        path: "/tournament",
        cName: "dropdown-link"
    },
]
import Keycloak from 'keycloak-js'
const _kc = new Keycloak('/keycloak.json')

const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
  }).then((authenticated) => {
    if(getToken()){
      localStorage.setItem('token', getToken())
    }
    onAuthenticatedCallback();
  })
};

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const getParseToken = () => _kc.tokenParsed

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getUID = () => _kc.tokenParsed?.sub;

const getFirstName = () => _kc.tokenParsed?.given_name;

const getLastName = () => _kc.tokenParsed?.family_name;

const getEmail = () => _kc.tokenParsed?.email;

const getEmployeeId = () => _kc.tokenParsed?.employeeNumber;

const updateToken = (successCallback) => {
  _kc.updateToken(5).then(successCallback).catch(doLogin);
}

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const KeycloakServices = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  updateToken,
  hasRole,
  getParseToken,
  isLoggedIn,
  getUsername,
  getUID,
  getFirstName,
  getLastName,
  getEmail,
  getEmployeeId
}

export default KeycloakServices
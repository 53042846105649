import { useState, useEffect } from 'react';

export const API_BASE_URL = 'https://qbprides.com';
export const CLIENT_ID = 1;
export const EVENT_ID = 22;
export const STATIC_MEDIA_URL = "https://qbp-static.fromlabs.com/";
export const APP_STRAVA_CLIENT_ID = process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : '73207';
export const APP_STRAVA_CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET ? process.env.REACT_APP_CLIENT_ID : 'a075bfa013561326c65bd88afa30f08f382f6902';

//https://dev.fitbit.com/apps/details/23BLGV
export const APP_FITBIT_CLIENT_ID = process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : '23BLGV';
export const APP_FITBIT_CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET ? process.env.REACT_APP_CLIENT_ID : '6842f7501197b021dfed8290ec60ec9a';

//need config redirect url in api 
export const redirectUrl = "https://qbprides.com/personal"
export const redirectLogOut = "https://qbprides.com"
export const FITBIT_BASE_URL = "https://api.fitbit.com";
export const STRAVA_AUTH_URL = `http://www.strava.com/oauth/authorize?client_id=${APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}&response_type=code&scope=activity:read_all`;
export const FITBIT_AUTH_URL = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${APP_FITBIT_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=activity`


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return windowDimensions;
}